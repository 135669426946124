import React from 'react';
import Layout from '../../components/Layout';
import { StaticQuery, graphql } from 'gatsby';
// import { Link } from 'gatsby';
import get from 'lodash/get';
import Helmet from 'react-helmet';
import SEO from 'components/SEO/SEO';
import logger from 'utils/logger';

import Header from 'components/Headers/Header';
import { ParallaxBanner } from 'react-scroll-parallax';

import { Container, Row, Col } from 'reactstrap';
import { Fluid, Offset } from 'components/Containers';

class Template extends React.Component {
  render () {
    logger.template(__filename, '>', 'render()');
    const siteTitle = get(this, 'props.data.site.siteMetadata.title');
    const data = get(this, 'props.data');
    logger.data('data', data);

    const headerProps = {
      height: `60vh`,
      bgColor: `primary-500`,
      bgImage: `/img/production-switcher-ob740.JPG`,
      parallax: [
        {
          image: '/img/production-switcher-ob740.JPG',
          amount: 0.35,
          slowerScrollRate: false
        }
      ]
    };
    logger.templateEnd();
    return (
      <Layout location={this.props.location}>
        <Fluid>
          <SEO title={`About Us`} />
          <Header {... headerProps}>
            <Offset className={`text-center`}>
              <h1 className={`fs-55em`}>About Us</h1>
            </Offset>
          </Header>
          <article>
            <section className="bg-primary-600 ptb-50em text-center bbd-4 btd-4">
              <Offset>
                <Row>
                  <Col lg={8} className={`mrl-auto`}>
                    <h1 className={`fs-14em fw-300 lh-16 ls-10em`}>Apantac LLC is a leading designer and developer of high quality, cost effective image signal processing equipment. The Apantac product line has been specifically designed to provide users with a flexible and innovative technology solution for image processing, and signal extension and processing.</h1>
                  </Col>
                </Row>
              </Offset>
            </section>
            <section className={`bg-gray-cold-500`}>
              <ParallaxBanner
                layers={[
                  {
                    image: '/img/Universiade-2017.jpg',
                    amount: 0.05,
                    slowerScrollRate: false
                  }
                ]}
                style={{ height: `auto` }}
              >
                <Offset>
                  <Row className={`ptb-50em text-center`}>
                    <Col lg={10} className={`mrl-auto`}>
                      <h1>Our Products</h1>
                      <Container className={`text-left pt-20em`}>
                        <Row>
                          <Col md={4} lg={3}>
                            <div className="mb-20em">
                              <h2 className={`fs-11em lh-11 mb-20`}><span className="fw-700">TAHOMA</span><br />Platform of Multiviewers</h2>
                              <ul className={`uls-none ml-0 fs-09em lh-11`}>
                                <li className={`mb-5em`}>T#</li>
                                <li className={`mb-5em`}>TAHOMA-LE32</li>
                                <li className={`mb-5em`}>TAHOMA-LI</li>
                                <li className={`mb-5em`}>TAHOMA-LX</li>
                                <li className={`mb-5em`}>TAHOMA-DL</li>
                                <li className={`mb-5em`}>TAHOMA-DE</li>
                                <li className={`mb-5em`}>TAHOMA-IP</li>
                                <li className={`mb-5em`}>TAHOMA-IL</li>
                                <li className={`mb-5em`}>TAHOMA-ID</li>
                                <li className={`mb-5em`}>Compact Quad-Splits, modular and standalone and openGear</li>
                              </ul>
                            </div>
                          </Col>
                          <Col md={4} lg={3}>
                            <div className="mb-20em">
                              <h2 className={`fs-11em lh-11 mb-20`}><span className="fw-700">TAHOMA CRESCENT</span> <br />Platform of Video Walls</h2>
                            </div>
                            <div className="mb-20em">
                              <h2 className={`fs-11em lh-11 mb-20`}><span className="fw-700">CRESCENT</span> Signal Processing</h2>
                              <ul className={`uls-none ml-0 fs-09em lh-11`}>
                                <li className={`mb-5em`}>Converters</li>
                                <li className={`mb-5em`}>Distribution Amplifiers</li>
                                <li className={`mb-5em`}>Deembedders</li>
                                <li className={`mb-5em`}>Test Pattern Generators</li>
                              </ul>
                            </div>
                          </Col>
                          <Col md={4} lg={3}>
                            <div className="mb-20em">
                              <h2 className={`fs-11em lh-11 mb-20`}><span className="fw-700">MAZAMA</span><br />Series Fiber Optic Signal</h2>
                              <ul className={`uls-none ml-0 fs-09em lh-11`}>
                                <li className={`mb-5em`}> DVI, HDMI, and SDI Fiber Optic Extenders</li>
                              </ul>
                            </div>
                            <div className="mb-20em">
                              <h2 className={`fs-11em pl-0 lh-11`}><span className="fw-700">openGear</span> Platform</h2>
                              <ul className={`uls-none ml-0 fs-09em lh-11`}>
                                <li className={`mb-5em`}>Multiviewers & Quad-Splits</li>
                                <li className={`mb-5em`}>Converters</li>
                                <li className={`mb-5em`}>Scalers</li>
                                <li className={`mb-5em`}>Distribution Amplifiers</li>
                                <li className={`mb-5em`}>Frames</li>
                              </ul>
                            </div>
                          </Col>
                          <Col md={4} lg={3}>
                            <div className="mb-20em">
                              <h2 className={`fs-11em lh-11 mb-20`}><span className="fw-700">MT HOOD</span><br />Series of Signal Processing</h2>
                              <ul className={`uls-none ml-0 fs-09em lh-11`}>
                                <li className={`mb-5em`}>CATx Extenders/Receivers</li>
                                <li className={`mb-5em`}>Scalers / Converters</li>
                                <li className={`mb-5em`}>Splitters</li>
                                <li className={`mb-5em`}>Matrices & Switches</li>
                                <li className={`mb-5em`}>EDID solutions</li>
                              </ul>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </Col>
                  </Row>
                </Offset>
              </ParallaxBanner>
            </section>
            <section>
              <Container>
                <Row>
                  <Col md={5} lg={6} className={`bg-gray-cold-800`}>
                    <div className="bg-container" style={{backgroundImage: `url(${`/img/TV-Nova.jpg`})`, backgroundPosition: `center`, backgroundSize: `cover`}}/>
                  </Col>
                  <Col md={{ size: 5, offset: 1 }} lg={{ size: 4, offset: 1 }} className={`ptb-50em`}>
                    <p className="lead fs-15em">These products are marketed globally through direct selling channels, a growing network of dealers, system integrators, OEMs and various other partners.</p>
                    <p>The Apantac product line is suitable for a variety of applications including:</p>
                    <ul>
                      <li>Broadcast, Media, and Post Production</li>
                      <li>Professional Audio Visual</li>
                      <li>Command and Control</li>
                      <li>IT and Enterprise (small, medium, large)</li>
                      <li>Medical Imaging</li>
                      <li>Digital Signage</li>
                      <li>Government</li>
                      <li>Education and Training</li>
                    </ul>
                  </Col>
                </Row>
                <Row>
                  <Col md={{ size: 5, offset: 1 }} lg={{ size: 4, offset: 1 }} className={`ptb-50em`}>
                    <p className="lead fs-15em">Apantac is well positioned to offer a complete solution for your image and signal processing needs.</p>
                    <p>With their products, representation of several manufacturers' product lines, as well as vast network of partners, Apantac offers complete audio video solutions and technologies, consultation and service to meet your specific requirements.</p>
                  </Col>
                  <Col md={5} lg={{ size: 6, offset: 1 }} className={`bg-gray-cold-800`}>
                    <div className="bg-container" style={{backgroundImage: `url(${`/img/kslnews_c.jpg`})`, backgroundPosition: `center`, backgroundSize: `cover`}}/>
                  </Col>
                </Row>
                <Row>
                  <Col md={5} lg={6} className={`bg-gray-cold-800`}>
                    <div className="bg-container" style={{backgroundImage: `url(${`/img/slomo0ob740b.jpg`})`, backgroundPosition: `center`, backgroundSize: `cover`}}/>
                  </Col>
                  <Col md={{ size: 5, offset: 1 }} lg={{ size: 4, offset: 1 }} className={`ptb-50em`}>
                    <p className="lead fs-15em">Apantac is positioned as a leader committed to delivering innovative processing solutions.</p>
                    <p>Apantac is led by seasoned industry veteran, Thomas Tang. Thomas has a commanding background that includes engineering, applications, sales and marketing, business management, and business development. The Apantac team has decades of combined experience in providing audio / video solutions for a variety of markets and includes a diverse group of engineers, product developers and designers, and management personnel. Capitalizing on this experience, product innovations through investment in research and development, and outstanding customer service, Apantac is positioned as a leader committed to delivering innovative processing solutions.</p>
                    <p>Apantac was founded in 2008 and is a privately held company with its headquarters located in Portland, Oregon, USA.</p>
                  </Col>
                </Row>
              </Container>
            </section>

          </article>
        </Fluid>
      </Layout>
    );
  }
}

const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export default ({ children }) => (
  <StaticQuery
    query={query}
    render={data => (
      <>
        <Helmet title={`${`About Us`}`} />
        <Template data={data}>
          {children}
        </Template>
      </>
    )}
  />
);


